var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"tile":"","height":"54"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),(_vm.userSlug)?_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.user.name)+" ")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-sheet',{attrs:{"height":"calc(100vh - 105px)"}},[_c('v-calendar',{ref:"calendar",class:_vm.holidayDays,attrs:{"type":_vm.type === 'mon - fri' ? 'week' : _vm.type,"color":"primary","start":_vm.startDate,"weekdays":_vm.weekdays,"events":_vm.entriesList,"event-color":_vm.eventColour,"event-timed":function () { return true; },"event-overlap-mode":'stack',"event-overlap-threshold":_vm.timeResolution,"interval-height":_vm.timeResolution,"interval-minutes":_vm.timeResolution,"interval-count":48,"interval-format":function (interval) { return interval.time; },"interval-width":50},on:{"change":_vm.weekChanged,"mousedown:time":_vm.mouseDownTime,"mousemove:time":_vm.mouseMoveTime,"touchstart:time":_vm.touchStartTime,"touchmove:time":_vm.touchMoveTime,"mouseup:time":_vm.mouseUpTime,"touchend:time":_vm.touchEndTime,"mousedown:event":_vm.mouseDownEvent,"mouseup:event":_vm.mouseUpEvent,"touchstart:event":_vm.touchStartEvent,"touchend:event":_vm.touchEndEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventParsed = ref.eventParsed;
return [_c('div',{staticClass:"event",class:{ lunch: !event.job && event.type === 'workshop', leave: event.type === 'leave' }},[(event.target)?_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.entryIcons[event.target]))]):_vm._e(),(event.type === 'leave')?_c('v-icon',[_vm._v(_vm._s(_vm.entryIcons['leave']))]):_vm._e(),(event.job && _vm.longerThan30mins(eventParsed))?_c('h3',[_vm._v(_vm._s(event.job.number))]):_vm._e(),_c('h4',{staticClass:"d-block"},[(event.type === 'workshop')?_c('span',[_vm._v(" "+_vm._s(_vm._f("maxLength")((event.job && event.job.name ? event.job.name : 'Lunch'),30))+" ")]):(event.type === 'shoot')?_c('span',[_vm._v(" "+_vm._s(_vm._f("maxLength")(event.job.name,30))+" ")]):(event.type === 'leave')?_c('span',[_vm._v(" Leave ")]):_vm._e()]),(_vm.userInOffice)?_c('span',{staticClass:"hours"},[_vm._v(" "+_vm._s(_vm.entryHours(event))+" ")]):_vm._e()],1),(timed && event.type && event.type !== 'shoot')?_c('div',{staticClass:"v-event-extend-bottom",class:{ extending: event.extending },on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendEvent(event)}}}):_vm._e()]}},{key:"day-label-header",fn:function(ref){
var day = ref.day;
var date = ref.date;
return [(_vm.userInOffice)?_c('span',{staticClass:"day-hours"},[_vm._v(" "+_vm._s(_vm.dayTotalHours[date] && ((_vm.dayTotalHours[date]) + "hrs"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"fab":"","light":"","depressed":"","color":date === _vm.today ? 'primary' : date === _vm.startDate ? 'warning' : 'white'}},[_vm._v(" "+_vm._s(day)+" ")])]}}]),model:{value:(_vm.calendarValue),callback:function ($$v) {_vm.calendarValue=$$v},expression:"calendarValue"}})],1),_c('v-dialog',{attrs:{"persistent":"","width":"unset"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('create-entry',{ref:"editor",attrs:{"user":_vm.user},on:{"close":function($event){_vm.dialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }