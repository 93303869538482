<template>
  <v-card ref="card" class="edit-entry">
    <v-card-title class="headline font-weight-regular">
      {{ (entryExists ? window : 'Create') | title }} entry
      <v-spacer></v-spacer>
      <span v-if="entry && entry.type" class="flag" @click="toggleFlagged">
        <v-icon v-if="flagged" color="warning">mdi-flag</v-icon>
        <v-icon v-else>mdi-flag-outline</v-icon>
      </span>
    </v-card-title>
    <v-window v-model="window">
      <v-window-item :value="'select'">
        <v-card-text>
          <v-row v-for="type in types" :key="type" dense>
            <v-col>
              <v-btn depressed width="300px" @click="selectType(type)">
                <v-icon class="mr-2">{{ entryIcons[type] }}</v-icon>
                {{ type | title }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-window-item>
      <v-window-item :value="'workshop'">
          <workshop-entry ref="workshop"
                          :user="user" :initial-entry="entry" :flagged="flagged" :flagged-changed="flaggedChanged"
                          @close="close"></workshop-entry>
      </v-window-item>
      <v-window-item :value="'shoot'">
        <shoot-entry ref="shoot"
                     :user="user" :initial-entry="entry" target="shoot" :flagged="flagged" :flagged-changed="flaggedChanged"
                     @close="close">
        </shoot-entry>
      </v-window-item>
      <v-window-item :value="'recce'">
        <shoot-entry ref="recce"
                     :user="user" :initial-entry="entry" target="recce" :flagged="flagged" :flagged-changed="flaggedChanged"
                     @close="close">
        </shoot-entry>
      </v-window-item>
      <v-window-item :value="'install_day'">
        <shoot-entry ref="install_day"
                     :user="user" :initial-entry="entry" target="install_day" :flagged="flagged" :flagged-changed="flaggedChanged"
                     @close="close">
        </shoot-entry>
      </v-window-item>
      <v-window-item :value="'travel_day'">
        <shoot-entry ref="travel_day"
                     :user="user" :initial-entry="entry" target="travel_day" :flagged="flagged" :flagged-changed="flaggedChanged"
                     @close="close">
        </shoot-entry>
      </v-window-item>
      <v-window-item :value="'leave'">
        <leave-entry ref="leave"
                     :user="user" :initial-entry="entry" :flagged="flagged" :flagged-changed="flaggedChanged"
                     @close="close">
        </leave-entry>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { entryOptions } from '../../data'
import WorkshopEntry from './WorkshopEntry'
import ShootEntry from './ShootEntry'
import LeaveEntry from './LeaveEntry'

export default {
  name: 'CreateEntry',
  components: { LeaveEntry, ShootEntry, WorkshopEntry },
  props: {
    user: Object
  },
  data() {
    return {
      window: 'select',
      entry: null,
      types: entryOptions.type,
      flagged: null,
      flaggedChanged: false
    }
  },
  computed: {
    entryExists() {
      return !this.entry?.notSaved
    }
  },
  methods: {
    setEntry(entry) {
      this.entry = { ...entry }
      this.flagged = null
      this.flaggedChanged = false
      if (entry.notSaved) {
        this.window = 'select'
      } else {
        this.selectType(entry)
      }
    },
    selectType(typeOrEntry) {
      let name
      if (typeof typeOrEntry === 'string') {
        name = typeOrEntry

        // set the type here, so we can use it for flags on new entries
        this.entry.type = typeOrEntry
        if (this.entry.type === 'recce' || this.entry.type === 'install_day' || this.entry.type === 'travel_day') {
          this.entry.type = 'shoot'
        }
      } else {
        name = typeOrEntry.type
        if (name === 'shoot') {
          name = typeOrEntry.target
        }
        this.flagged = !!typeOrEntry.flagged
      }
      this.window = name
      this.$nextTick(() => {
        const ref = this.$refs[name]
        if (ref) {
          ref.setEntry(this.entry)
        }
        // so esc works without having to click on dialog
        this.$refs.card.$el.parentElement.parentElement.focus()
      })
    },
    toggleFlagged() {
      if (!this.flagged || this.$store.state.user.admin) {
        this.flagged = !this.flagged
        this.flaggedChanged = true
      }
    },
    close() {
      this.$emit('close')
      this.window = 'select'
    }
  }
}
</script>

<style lang="scss">
.edit-entry {
  @media (min-width: 700px) {
    .v-window__container {
      padding: 10px;
    }
  }
  span.flag {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
