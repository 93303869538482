<template>
<div class="shoot-entry">
  <v-card-text>
    <v-container v-if="entry.start" class="pa-0">
      <v-row dense>
        <v-col cols="12" sm="10">
          <job-select v-model="jobID" :exclude="['paid']"></job-select>
        </v-col>
        <v-col cols="6" sm="2">
          <date-picker v-model="startDate" label="On"></date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row dense>
            <v-col cols="6">
              <time-picker v-model="departHomeTime" label="Depart home" :hint="callTime" clearable></time-picker>
            </v-col>
            <v-col cols="6">
              <time-picker v-model="startAtBaseTime" label="Start at base" :hint="callTime" clearable></time-picker>
            </v-col>
          </v-row>
          <v-row v-if="!isTravelDay" dense>
            <v-col cols="6">
              <time-picker v-model="callTime" label="Call"></time-picker>
            </v-col>
          </v-row>
          <v-row v-if="!isTravelDay" dense>
            <v-col cols="6">
              <time-picker v-model="lunchTime" label="Lunch" :hint="callTime" clearable></time-picker>
            </v-col>
            <v-col cols="6">
              <v-select v-model="lunchHours" :items="lunchHoursOptions" label="Lunch duration"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="!isTravelDay" dense>
            <v-col cols="6">
              <time-picker v-model="wrapTime" :hint="callTime" label="Wrap"></time-picker>
            </v-col>
            <v-col cols="6">
              <time-picker v-model="departTime" :hint="callTime" label="Depart"></time-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <time-picker v-model="finishAtBaseTime" :label="`Finish ${isTravelDay ? '' : 'at base'}`"
                           :hint="wrapTime" clearable>
              </time-picker>
            </v-col>
            <v-col v-if="!isTravelDay" cols="6">
              <time-picker v-model="arriveHomeTime" label="Arrive home" :hint="wrapTime" clearable></time-picker>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" sm="1" class="d-none d-sm-flex"></v-col>
        <v-col cols="12" sm="5">
          <v-row dense>
            <v-col>
              <journeys ref="journeys" @update="updateValidation"></journeys>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field v-model="entry.notes" label="Notes" placeholder="Any notes"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense v-if="allowsRoleChange || hasPaidRoles">
        <v-col v-if="hasPaidRoles" cols="6" sm="4">
          <v-select v-model="paidRole" :items="paidRoleOptions" label="Working as"></v-select>
        </v-col>
        <v-col v-if="allowsRoleChange" cols="6" sm="4">
          <v-select v-model="entry.as_role" :items="roleOptions" label="As role"></v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-btn v-if="!entry.notSaved" text color="error" @click="remove">Delete</v-btn>
    <entry-copy ref="copy" :entry="entry"></entry-copy>
    <span class="flex-grow-1 text-center grey--text">
      <span v-if="endBeforeStart">End needs to be after start</span>
      <span v-if="clashes">Times clash with other entries</span>
    </span>
    <v-btn text @click="$emit('close')">Cancel</v-btn>
    <v-btn text :disabled="saveDisabled()" @click="save">
      Save
    </v-btn>
  </v-card-actions>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import camelCase from 'camelcase'
import dayjs from 'dayjs'
import JobSelect from '../ui/JobSelect'
import { entryExtraTimeFields } from '../../data'
import { createDateTime, extractDate, extractTime, maxValue, minValue } from '../../util'
import DatePicker from '../ui/DatePicker'
import TimePicker from '../ui/TimePicker'
import Journeys from './Journeys'
import EntryCopy from './EntryCopy'
import { entryMixin } from '../../mixins/EntryMixin'
import { dataWatchMixin } from '../../mixins/DataWatchMixin'

export default {
  name: 'ShootEntry',
  mixins: [entryMixin, dataWatchMixin],
  components: { EntryCopy, Journeys, TimePicker, DatePicker, JobSelect },
  props: {
    target: String
  },
  data() {
    return {
      jobID: null,
      startDate: null,
      departHomeTime: null,
      startAtBaseTime: null,
      callTime: null,
      lunchTime: null,
      wrapTime: null,
      departTime: null,
      finishAtBaseTime: null,
      arriveHomeTime: null,
      lunchHours: null,
      lunchHoursOptions: [
        { text: 'Not given', value: 0 },
        { text: '30 mins', value: 0.5 },
        { text: '1 hour', value: 1 }
      ]
    }
  },
  computed: {
    ...mapState(['jobs', 'userPreferences']),
    ...mapGetters(['jobForID', 'jobForDay', 'dayHasLunch']),
    jobsList() {
      return this.jobs.filter(job => job.status === 'in progress' || job.status === 'completed')
    },
    allowsRoleChange() {
      return this.user.allow_role_change || this.$store.state.user.admin
    },
    endBeforeStart() {
      // if (this.entry.start && this.entry.end) {
      //   return this.entry.end.localeCompare(this.entry.start) !== 1
      // }
      return false
    },
    clashes() {
      return false // this.$store.getters.entryClashes(this.entry)
    },
    isTravelDay() {
      return this.target === 'travel_day'
    }
  },
  methods: {
    ...mapMutations(['createEntry']),
    setEntry(entry) {
      this.unwatchData()
      this.entry = { ...entry }

      this.jobID = entry.job?.id || null
      this.paidRole = entry.paid_role || 0

      if (!this.jobID && this.userPreferences.autofillDayJob) {
        this.jobID = this.jobForDay(this.entry.start)
      }

      this.startDate = extractDate(this.entry.start, true)
      this.departHomeTime = extractTime(this.entry.depart_home, true)
      this.callTime = extractTime(this.entry.call || this.entry.start, true)

      const duration = (new Date(this.entry.end) - new Date(this.entry.start)) / 1000 / 60
      let end = this.entry.end
      if (duration <= 30) { // why??
        end = null
      }

      this.wrapTime = extractTime(this.entry.wrap || end, true)
      this.departTime = extractTime(this.entry.depart || end, true)

      if (this.isTravelDay) {
        this.startAtBaseTime = extractTime(this.entry.start_at_base || this.entry.start, true)
        this.lunchTime = null
        this.lunchHours = 0
        this.finishAtBaseTime = extractTime(this.entry.finish_at_base || end, true)
        this.arriveHomeTime = null
      } else {
        this.startAtBaseTime = extractTime(this.entry.start_at_base, true)
        this.lunchTime = extractTime(this.entry.lunch, true)
        this.lunchHours = this.entry.lunch_hours >= 0 ? this.entry.lunch_hours : null
        this.finishAtBaseTime = extractTime(this.entry.finish_at_base, true)
        this.arriveHomeTime = extractTime(this.entry.arrive_home, true)
      }

      this.$nextTick(() => {
        this.$refs.journeys.setValues(this.entry)
      })

      this.$refs.copy.reset()

      this.watchData()
      this.addWatch('entry.notes', this.entry.notes)
      this.addWatch('entry.as_role', this.entry.as_role)
    },
    async save() {
      const entry = { ...this.entry }
      entry.job = this.jobForID(this.jobID)
      entry.paid_role = this.paidRole

      if (entry.as_role === this.user.role) {
        entry.as_role = null
      }

      if (this.isTravelDay) {
        this.callTime = this.startAtBaseTime
        this.wrapTime = this.finishAtBaseTime
        this.departTime = this.finishAtBaseTime
      }

      let last_dt = null
      for (const timeField of entryExtraTimeFields.shoot) {
        const key = `${camelCase(timeField)}Time`
        const value = this.$data[key]
        let dt = value ? createDateTime(this.startDate, this.$data[key]) : null

        // rollover day if needed
        if (dt && last_dt && dt < last_dt) {
          dt = dayjs(dt).add(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        }
        entry[timeField] = dt
        if (dt) {
          last_dt = dt
        }
      }

      entry.lunch_hours = this.lunchHours
      entry.start = minValue(entry.depart_home, entry.start_at_base, entry.call)
      entry.end = maxValue(entry.wrap, entry.depart, entry.finish_at_base, entry.arrive_home)

      const journeys = this.$refs.journeys
      entry.outward_mode = journeys.outwardMode
      entry.return_mode = journeys.returnMode
      entry.outward_vehicle = journeys.outwardVehicle
      entry.return_vehicle = journeys.returnVehicle
      entry.mileage = journeys.mileage

      const { target } = this
      const payload = { type: 'shoot', target, ...entry }

      if (this.hasChanged || this.$refs.journeys?.hasChanged) {
        const action = entry.notSaved ? 'addEntry' : 'updateEntry'
        await this.$store.dispatch(action, payload)
      }

      await this.$refs.copy.execute(payload)

      this.processFlagged(payload)

      this.$emit('close')
    },
    async remove() {
      await this.$store.dispatch('removeEntry', this.entry)
      this.$emit('close')
    },
    updateValidation() {
      this.$forceUpdate()
    },
    saveDisabled() {
      const travelDayStarted = this.departHomeTime || this.startAtBaseTime
      const changed = this.hasChanged || this.$refs.journeys?.hasChanged || this.flaggedChanged
      const copying = this.$refs.copy?.hasDates
      return (
        !changed ||
        !this.jobID || this.endBeforeStart || this.clashes ||
        (!this.isTravelDay && (!this.callTime || !this.wrapTime || !this.departTime)) ||
        (this.isTravelDay && (!this.finishAtBaseTime || !travelDayStarted)) ||
        this.lunchHours === null || (this.lunchHours && !this.lunchTime) ||
        !this.$refs.journeys?.valid
      ) && !copying
    }
  },
  mounted() {
    if (this.initialEntry) {
      this.setEntry(this.initialEntry)
    }
  }
}
</script>

<style lang="scss">
div.shoot-entry {
  .v-input__slot {
    margin-bottom: unset;
  }

  .v-text-field__details {
    display: none;
  }
}

@media (min-width: 770px) {
  div.shoot-entry {
    width: 700px;
  }
}

@media (max-width: 700px) {
}

.edit-entry {
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 5px;
  }
  .v-select__selections {
    flex-wrap: unset;
  }
}
</style>
