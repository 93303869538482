export const userOrAdminMixin = {
  props: {
    userSlug: String
  },
  computed: {
    user() {
      if (this.$store.state.user.admin && this.userSlug) {
        return this.$store.getters.userFromSlug(this.userSlug)
      }
      return this.$store.state.user
    }
  }
}
