<template>
  <v-autocomplete v-model="jobID" :items="jobsList"
                  ref="autocomplete"
                  label="Job" placeholder="Search or select a job"
                  :loading="jobsList.length === 0"
                  :disabled="disabled"
                  @change="change">
    <template v-slot:item="{ item }">
      <strong class="mr-2 job-number">{{ item.number }}</strong>
      <span class="grey--text text--darken-2">{{ item.name }}</span>
    </template>
    <template v-slot:selection="{ item }">
      <strong class="mr-2 job-number">{{ item.number }}</strong>
      <span class="grey--text text--darken-2">{{ item.name | maxLength(40) }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import { MAX_OVERHEAD_JOB_NUMBER } from '../../data'

export default {
  name: 'JobSelect',
  props: {
    value: Number,
    statuses: {
      type: Array,
      default: null
    },
    exclude: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addAllOption: {
      type: Boolean,
      default: false
    },
    addOverheadsOption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['jobs']),
    jobID: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    jobsList() {
      let jobs = this.jobs
      if (this.statuses) {
        jobs = jobs.filter(job => this.statuses.includes(job.status) || job.id === this.value)
      }
      if (this.exclude) {
        jobs = jobs.filter(job => !this.exclude.includes(job.status) || job.id === this.value)
      }
      if (this.addOverheadsOption) {
        jobs = [{
          value: MAX_OVERHEAD_JOB_NUMBER,
          text: 'Overheads',
          number: 'OVR',
          name: 'Overheads'
        }, ...jobs]
      }
      if (this.addAllOption) {
        jobs = [{
          value: 0,
          text: 'All jobs',
          number: 'ALL',
          name: ''
        }, ...jobs]
      }

      return jobs
    }
  },
  methods: {
    change() {
      this.$emit('change')
      this.$refs.autocomplete.blur()
    }
  },
  mounted() {
    this.$store.dispatch('getJobs')
  }
}
</script>

<style scoped>

</style>
