export const dataWatchMixin = {
  data() {
    return {
      dataWatchers: [],
      changes: {},
      prevValues: {}
    }
  },
  computed: {
    hasChanged() {
      const keys = Object.keys(this.changes)
      // eslint-disable-next-line eqeqeq
      return keys.length > 0 && keys.some(k => this.changes[k] != this.prevValues[k])
    },
    isWatchingData() {
      return this.dataWatchers.length > 0
    }
  },
  methods: {
    addWatch(key, initialValue) {
      this.prevValues[key] = initialValue
      const unwatch = this.$watch(key, (newValue) => {
        if (typeof newValue === 'string' && newValue.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
          newValue = newValue.slice(0, 5)
        }
        this.$set(this.changes, key, newValue)
      })
      this.dataWatchers.push(unwatch)
    },
    watchData() {
      if (!this.isWatchingData) {
        const dataEntries = Object.entries(this.$data)
        const keep = ['number', 'boolean', 'string']
        this.changes = {}
        for (const [key, value] of dataEntries) {
          const type = typeof value
          if (key !== 'hasChanged' && (keep.includes(type) || value === null)) {
            this.addWatch(key, value)
          }
        }
      }
    },
    unwatchData() {
      for (const unwatch of this.dataWatchers) {
        unwatch()
      }
      this.dataWatchers = []
    }
  }
}
