import * as api from '../api'
import { userOptions } from '../data'

export const entryMixin = {
  props: {
    user: Object,
    initialEntry: Object,
    flagged: Boolean,
    flaggedChanged: Boolean
  },
  data() {
    return {
      entry: {},
      paidRole: 0
    }
  },
  computed: {
    roleOptions() {
      const roles = userOptions.role.map(r => ({ value: r, text: this.makeFullTitle(r) }))
      return [{ value: null, text: 'Default' }, ...roles]
    },
    hasPaidRoles() {
      return this.user.paid_roles.length > 0
    },
    paidRoleOptions() {
      const roles = this.user.paid_roles.map(role => ({
        text: role.name,
        value: role.id
      }))
      return [{ text: 'Normal', value: 0 }, ...roles]
    }
  },
  methods: {
    processFlagged(entry) {
      if (this.flaggedChanged) {
        entry.flagged = this.flagged
        this.$store.commit('updateEntry', entry)
        const { uid, type, flagged } = entry
        return api.put('/entry/flag', { uid, entry_type: type, flagged })
      }
    }
  }
}
