<template>
  <v-app :class="{ dev }">
    <header v-if="dev" class="dev"></header>
    <app-bar></app-bar>
    <v-main class="grey lighten-3">
      <router-view class="main-content"></router-view>
    </v-main>
    <v-dialog v-model="noServerConnection" persistent max-width="600">
      <v-alert prominent type="error">
        Lost connection to server, please refresh
      </v-alert>
    </v-dialog>
  </v-app>
</template>

<script>
import AppBar from './components/ui/AppBar'
import * as api from './api'
import { mapState } from 'vuex'

export default {
  components: {
    AppBar
  },
  data() {
    return {
      apiBuild: null
    }
  },
  computed: {
    ...mapState(['noServerConnection']),
    dev() {
      return process.env.VUE_APP_STAGE === 'dev'
    }
  },
  methods: {
    async queryApiBuild() {
      const { build } = await api.get('/version')
      if (this.apiBuild !== null && build !== this.apiBuild) {
        location.reload()
      }
      this.apiBuild = build
    }
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      this.$set(this.$store.state.keysDown, e.key, true)
    })
    window.addEventListener('keyup', (e) => {
      this.$delete(this.$store.state.keysDown, e.key)
    })

    if (this.$store.state.user) {
      this.$store.dispatch('refreshUser')
      this.$store.dispatch('loadUserPreferences')
    }

    if (process.env.NODE_ENV !== 'development') {
      document.addEventListener('contextmenu', event => event.preventDefault())
    }

    this.queryApiBuild()

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.queryApiBuild()
      }
    })

    // window.addEventListener('contextmenu', (e) => {
    //   e.preventDefault()
    // }, true)
  }
}
</script>

<style lang="scss">
.container {
  max-width: 1300px;
}

span.pounds {
  &::before {
   content: "£";
   margin-right: 2px;
 }
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.hover-underline:hover {
  text-decoration: underline !important;
}

div.dev {
  header.dev {
    position: fixed;
    width: 100%;
    height: 3px;
    background: #b05eff;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.v-alert {
  margin-bottom: 0 !important;
}
</style>
