<template>
  <v-menu transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="formatDate(date)" :label="label"
                    readonly v-bind="attrs" v-on="on">
      </v-text-field>
    </template>
    <v-date-picker v-model="date"
                   no-title first-day-of-week="1"
                   :disabled="disabled">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'date-picker',
  props: ['value', 'label', 'disabled'],
  computed: {
    date: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    formatDate(date) {
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>
