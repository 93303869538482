<template>
<div>
  <v-select ref="select" class="d-none d-sm-flex" v-model="time" :items="times" :label="label"
            menu-props="auto" hide-details :disabled="disabled" :clearable="clearable"
            @focus="focused">
  </v-select>
  <v-dialog v-model="showDialog" max-width="300px" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="time" :label="label" class="d-flex d-sm-none"
                    readonly v-bind="attrs" v-on="on">
      </v-text-field>
    </template>
    <v-time-picker v-if="showDialog"
                   v-model="time" format="24hr"
                   :allowed-minutes="this.minuteOptions"
                   @click:minute="showDialog = false">
      <v-spacer></v-spacer>
      <v-btn v-if="clearable" text @click="time = null; showDialog = false">Clear</v-btn>
      <v-btn text @click="showDialog = false">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</div>
</template>

<script>
export default {
  name: 'time-picker',
  props: {
    value: String,
    label: String,
    hint: String,
    disabled: Boolean,
    clearable: Boolean
  },
  data() {
    return {
      showDialog: false,
      minuteIncrement: 30
    }
  },
  computed: {
    time: {
      get() {
        return this.value && this.value.slice(0, 5) // no seconds
      },
      set(v) {
        const time = v ? `${v}:00` : v // add seconds or keep null
        this.$emit('input', time)
        this.$emit('change', time)
      }
    },
    minuteOptions() {
      return Array(60 / this.minuteIncrement).fill(0).map((_, i) => i * this.minuteIncrement)
    },
    times() {
      const output = []
      for (let hour = 0; hour < 24; hour++) {
        for (const minute of this.minuteOptions) {
          const text = `${this.doubleDigit(hour)}:${this.doubleDigit(minute)}`
          output.push(text)
        }
      }
      return output
    }
  },
  methods: {
    focused() {
      if (!this.value && this.hint) {
        const times = this.times
        let timeIndex = null
        for (let i = 0; i < times.length; i++) {
          const time = times[i]
          if (this.hint.startsWith(time)) {
            timeIndex = i
          }
        }
        if (timeIndex !== null) {
          setTimeout(() => {
            const content = this.$refs.select.$refs.menu.$refs.content
            if (content) {
              const itemHeight = 48
              content.scrollTop = itemHeight * timeIndex
            }
          }, 100)
        }
      }
    }
  }
}
</script>
