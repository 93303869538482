<template>
<div class="main-app-bar">
  <v-app-bar app flat color="#fff" height="50px">
    <v-container class="py-0 fill-height">
      <v-app-bar-nav-icon v-if="showNav" class="d-flex d-sm-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="logo-container">
        <v-avatar size="32">
          <img src="/logo.png" alt="logo"/>
        </v-avatar>
      </div>
      <div v-if="true">
        <v-tabs align-with-title class="d-none d-sm-flex">
          <v-tab v-for="link in links" :key="link" class="link"
                 :to="{ name: link }">
            {{ adjustLink(link) | title }}
          </v-tab>
        </v-tabs>
      </div>
      <v-spacer></v-spacer>
      <template v-if="user">
        {{ user.name }}
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </span>
          </template>
          <v-list flat class="text-right">
            <v-list-item>
              <v-list-item-title>
                <v-btn text @click="settingsOpen = true">Settings</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn text @click="logout">Logout</v-btn>
              </v-list-item-title>
            </v-list-item>
            <div class="text-left grey--text ml-4">{{ version }}</div>
          </v-list>
        </v-menu>
      </template>
    </v-container>
    <v-dialog v-model="settingsOpen" max-width="640px">
      <user-settings @close="settingsOpen = false"></user-settings>
    </v-dialog>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" absolute temporary width="120px">
    <v-list nav dense>
      <v-list-item v-for="link in links" :key="link" :to="{ name: link }">
        <v-list-item-title>{{ adjustLink(link) | title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>
import { mapState } from 'vuex'
import UserSettings from '../user/UserSettings'

export default {
  name: 'Header',
  components: { UserSettings },
  data() {
    return {
      drawer: false,
      settingsOpen: false,
      version: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    ...mapState(['user']),
    links() {
      const output = []
      if (this.user) {
        output.push('calendar')
        if (this.user.admin) {
          output.push('users', 'overview', 'job-times', 'staff-payroll', 'freelance-payroll', 'job-costs', 'analysis')
        } else if (this.user.status === 'freelance') {
          output.push('freelance-payroll')
        }
      }
      return output
    },
    showNav() {
      return this.$route.name !== 'login'
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push({ name: 'login' })
    },
    adjustLink(link) {
      if (this.user.status === 'freelance' && !this.user.admin) {
        if (link === 'freelance-payroll') {
          return 'invoices'
        }
      }
      // else if (link.match('-payroll')) {
      //   return link.replace('-payroll', '')
      // }
      return link
    }
  }
}
</script>

<style scoped lang="scss">
div.container {
  height: unset;
}

div.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;

  span {
    font-size: 0.7rem;
    color: #555;
  }
}
a.link {
  text-decoration: none;
}

a.link.v-tab {
  min-width: unset;
  padding: 0 10px;

  @media (max-width: 960px) {
    font-size: 0.8rem;
    padding: 0 5px;
  }
}
</style>

<style>
div.main-app-bar header .v-toolbar__content {
  padding: 0;
}
</style>
