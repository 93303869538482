<template>
<div class="login">
  <v-card class="mx-auto my-12" max-width="480">
    <v-card-title>Login</v-card-title>
    <v-card-text>
      <form @submit.prevent>
        <v-row dense align="center">
          <v-col>
            <v-text-field v-model="email" label="Email"
                          @keydown.space="(event) => event.preventDefault()">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense align="center" class="passcode">
          <v-col cols="9">
            <v-text-field v-model="password" type="password" label="Password"
                          :error-messages="errorMessage"
                          autocomplete="current-password"
                          @focus="onPasscode = true" @blur="onPasscode = false">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn type="submit" class="enter" depressed dark
                   :disabled="!valid"
                   @click="process">
              Login
            </v-btn>
          </v-col>
        </v-row>
      </form>
      <v-row>
        <v-col>
          <v-btn x-small text width="100%" :disabled="!isValidEmail(email)"
                 @click="resetPassword">
            Reset password
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import * as api from '../api'
import { hash } from '../util'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      active: {},
      errorMessage: null,
      onPasscode: false
    }
  },
  computed: {
    valid() {
      return this.isValidEmail(this.email) && this.password
    }
  },
  methods: {
    async process() {
      const query = {
        email: this.email,
        passcode_hash: hash(this.password)
      }
      const { correct, user } = await api.get('/user/login', query)

      if (correct) {
        this.$store.commit('login', user)
        await this.$router.push({ name: 'calendar' })
      } else {
        this.errorMessage = 'Incorrect password'
        setTimeout(() => {
          this.errorMessage = null
        }, 1000)
      }
    },
    resetPassword() {
      api.post('/user/reset/password', { email: this.email })
    }
  },
  beforeMount() {
    this.$store.commit('logout') // in case we redirect
  }
}
</script>

<style scoped lang="scss">
div.login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .theme--dark.v-btn:hover::before, button.touch {
    opacity: 0.3;
    //transition: opacity 0.1s;
  }
  button.enter {
    font-size: 0.85rem;
    padding: 0;
    &.v-btn--disabled {
      background: #bbb !important;
    }
  }
}
</style>
