<template>
<div class="calendar">
  <v-sheet tile height="54" class="d-flex align-center">
    <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-toolbar-title class="ma-2" v-if="$refs.calendar">
      {{ $refs.calendar.title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="userSlug">
      <h2>
        {{ user.name }}
      </h2>
    </div>
    <v-spacer></v-spacer>
<!--    <v-select v-model="type" :items="types" dense outlined-->
<!--              hide-details class="ma-2 fit">-->
<!--      <template slot="selection" slot-scope="data">-->
<!--        <span class="text-capitalize">{{ data.item }}</span>-->
<!--      </template>-->
<!--      <template slot="item" slot-scope="data">-->
<!--        <span class="text-capitalize">{{ data.item }}</span>-->
<!--      </template>-->
<!--    </v-select>-->
    <v-btn icon class="ma-2" @click="$refs.calendar.next()">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </v-sheet>
  <v-sheet height="calc(100vh - 105px)">
    <v-calendar ref="calendar" v-model="calendarValue" :type="type === 'mon - fri' ? 'week' : type"
                color="primary"
                :start="startDate"
                :weekdays="weekdays" :events="entriesList"
                :event-color="eventColour" :event-timed="() => true"
                :event-overlap-mode="'stack'" :event-overlap-threshold="timeResolution"
                :interval-height="timeResolution" :interval-minutes="timeResolution" :interval-count="48"
                :interval-format="(interval) => interval.time" :interval-width="50"
                :class="holidayDays"
                @change="weekChanged"
                @mousedown:time="mouseDownTime" @mousemove:time="mouseMoveTime"
                @touchstart:time="touchStartTime" @touchmove:time="touchMoveTime"
                @mouseup:time="mouseUpTime" @touchend:time="touchEndTime"
                @mousedown:event="mouseDownEvent" @mouseup:event="mouseUpEvent"
                @touchstart:event="touchStartEvent" @touchend:event="touchEndEvent">
      <template v-slot:event="{ event, timed, eventParsed }">
        <div class="event" :class="{ lunch: !event.job && event.type === 'workshop', leave: event.type === 'leave' }">
          <v-icon v-if="event.target" dark>{{ entryIcons[event.target] }}</v-icon>
          <v-icon v-if="event.type === 'leave'">{{ entryIcons['leave'] }}</v-icon>
          <h3 v-if="event.job && longerThan30mins(eventParsed)">{{ event.job.number }}</h3>
          <h4 class="d-block">
            <span v-if="event.type === 'workshop'">
              {{ (event.job && event.job.name ? event.job.name : 'Lunch') | maxLength(30) }}
            </span>
            <span v-else-if="event.type === 'shoot'">
              {{ event.job.name | maxLength(30) }}
            </span>
            <span v-else-if="event.type === 'leave'">
              Leave
            </span>
          </h4>
          <span v-if="userInOffice" class="hours">
            {{ entryHours(event) }}
          </span>
        </div>
        <div v-if="timed && event.type && event.type !== 'shoot'" class="v-event-extend-bottom"
             :class="{ extending: event.extending }"
             @mousedown.stop="extendEvent(event)">
        </div>
      </template>
      <template v-slot:day-label-header="{ day, date }">
        <span v-if="userInOffice" class="day-hours">
          {{ dayTotalHours[date] && `${dayTotalHours[date]}hrs`}}
        </span>
        <v-btn fab light depressed
               :color="date === today ? 'primary' : date === startDate ? 'warning' : 'white'">
          {{ day }}
        </v-btn>
      </template>
    </v-calendar>
  </v-sheet>
  <v-dialog v-model="dialog" persistent width="unset" @keydown.esc="dialog = false">
    <create-entry ref="editor" :user="user" @close="dialog = false"></create-entry>
  </v-dialog>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { msToDatetime, datetimeToMs } from '../util'
import CreateEntry from '../components/entry/CreateEntry'
import dayjs from 'dayjs'
import { userOrAdminMixin } from '../mixins/UserOrAdminMixin'

export default {
  components: {
    CreateEntry
  },
  mixins: [userOrAdminMixin],
  data() {
    return {
      currentEntry: null,
      dialog: false,
      type: 'week',
      types: ['month', 'week', 'mon - fri', 'day'],
      calendarValue: '',
      holdDuration: 1000,
      disableHold: false,
      lastUpTime: 0,
      timeResolution: 30, // minutes,
      holidayDays: {},
      atDate: null,
      entryUID: null
    }
  },
  watch: {
    type() {
      this.scrollToWorkHours()
    },
    dialog(v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs.editor.setEntry(this.currentEntry)
        })
      } else {
        if (!this.entrySynced(this.currentEntry)) {
          this.deleteEntry(this.currentEntry)
        }
        this.currentEntry = null
      }
    },
    userSlug(slug) {
      this.fetchData()
    }
  },
  computed: {
    ...mapState(['jobColours', 'dayTotalHours']),
    ...mapGetters(['entriesList', 'entrySynced', 'keyIsPressed', 'userInOffice']),
    weekdays() {
      const output = [1, 2, 3, 4, 5]
      if (this.type === 'mon - fri') return output
      return output.concat(6, 0)
    },
    currentEventChanged() {
      const { start, end, downStartMs, downEndMs } = this.currentEntry
      return datetimeToMs(start) !== downStartMs || datetimeToMs(end) !== downEndMs
    },
    today() {
      return (new Date()).toISOString().substr(0, 10)
    },
    startDate() {
      return this.atDate || this.today
    }
  },
  methods: {
    ...mapMutations(['createEntry', 'deleteEntry', 'prepareWeekCache']),
    async weekChanged({ start, end }) {
      const week = { startDate: start.date, endDate: end.date }
      this.setHolidayDays(week)

      await this.$store.dispatch('getUserEntries', {
        userSlug: this.userSlug,
        start: dayjs(start.date).subtract(1, 'week').format('YYYY-MM-DD'),
        end: dayjs(end.date).add(1, 'week').format('YYYY-MM-DD')
      })

      if (this.entryUID) {
        const entry = this.$store.state.entries[this.entryUID]
        if (entry) {
          this.currentEntry = { ...entry }
          this.dialog = true
        }
      }

      this.prepareWeekCache({ ...week, refresh: true })
    },
    tmsToMs(tms, roundFunc = 'floor') {
      const { year, month, day, hour, minute } = tms
      const roundedMinute = Math[roundFunc](minute / this.timeResolution) * this.timeResolution
      const date = new Date(year, month - 1, day, hour, roundedMinute)
      return date.getTime()
    },
    mouseDownTime(tms) {
      const time = this.tmsToMs(tms)

      if (this.currentEntry) {
        this.currentEntry.downTime = time
        return
      }

      const start = msToDatetime(time)
      const end = msToDatetime(time + 1000 * 60 * this.timeResolution)
      const event = { start, end, user: this.user }

      this.createEntry(event)
      this.currentEntry = event
      this.currentEntry.extending = true
      this.currentEntry.initial = true
    },
    mouseMoveTime(tms) {
      if (this.currentEntry && this.currentEntry.type !== 'shoot') {
        const event = this.currentEntry
        if (event.extending) {
          const minEndMs = datetimeToMs(event.start) + this.timeResolution * 60 * 1000
          const endMs = this.tmsToMs(tms, 'round')
          const end = msToDatetime(Math.max(endMs, minEndMs))
          if (end !== event.end && end !== event.start) {
            event.end = end
            event.moved = true
          }
        } else if (event.downTime) {
          const now = this.tmsToMs(tms)
          const { downStartMs, downEndMs, downTime } = event
          const diff = now - downTime
          event.start = msToDatetime(downStartMs + diff)
          event.end = msToDatetime(downEndMs + diff)
          if (diff !== 0) {
            event.moved = true
          }
        }
      }
    },
    mouseDownEvent({ event }) {
      let entry = event
      if (this.keyIsPressed('Alt') && entry.type !== 'shoot') {
        entry = { ...event, user: this.user, spawned: true }
        this.createEntry(entry)
        this.$store.dispatch('addEntry', entry)
      }
      this.currentEntry = Object.assign(entry, {
        downStartMs: datetimeToMs(entry.start),
        downEndMs: datetimeToMs(entry.end)
      })
    },
    mouseUpEvent({ event }) {
      const e = this.currentEntry
      this.dialog = !(e && e.moved)
    },
    extendEvent(event) {
      this.currentEntry = Object.assign(event, {
        downStartMs: datetimeToMs(event.start),
        downEndMs: datetimeToMs(event.end),
        extending: true
      })
    },
    mouseUpTime() {
      if (this.currentEntry?.initial) {
        this.dialog = true
      } else if (this.currentEntry && this.currentEventChanged) {
        const clash = this.$store.getters.entryClashes(this.currentEntry)
        if (clash) {
          const { downStartMs, downEndMs, spawned } = this.currentEntry
          if (spawned) {
            this.deleteEntry(this.currentEntry)
            this.$store.dispatch('removeEntry', this.currentEntry)
          } else {
            this.currentEntry.start = msToDatetime(downStartMs)
            this.currentEntry.end = msToDatetime(downEndMs)
          }
        } else {
          this.$store.dispatch('updateEntry', this.currentEntry)
        }
      }

      if (this.currentEntry) {
        delete this.currentEntry.extending
        delete this.currentEntry.downStartMs
        delete this.currentEntry.downEndMs
        delete this.currentEntry.downTime
        delete this.currentEntry.moved
        delete this.currentEntry.initial
      }

      if (!this.dialog) {
        this.currentEntry = null
      }
    },
    touchStartTime(tms) {
      this.disableHold = false
      setTimeout(() => {
        const now = (new Date()).getTime()
        if (now - this.lastUpTime > this.holdDuration && !this.disableHold) {
          this.mouseDownTime(tms)
        }
      }, this.holdDuration)
    },
    touchEndTime() {
      this.lastUpTime = (new Date()).getTime()
      if (this.currentEntry) {
        this.mouseUpTime()
      }
    },
    touchMoveTime(tms) {
      this.mouseMoveTime(tms)
    },
    touchStartEvent(event) {
      this.disableHold = false
      setTimeout(() => {
        const now = (new Date()).getTime()
        if (now - this.lastUpTime > this.holdDuration && !this.disableHold) {
          this.mouseDownEvent(event)
        }
      }, this.holdDuration)
    },
    touchEndEvent(event) {
      if (this.currentEntry) {
        this.mouseUpEvent(event)
      }
    },
    scrollToWorkHours() {
      if (this.type !== 'month') {
        this.$nextTick(() => {
          this.$refs.calendar.scrollToTime({
            hour: 7,
            minute: 30
          })
        })
      }
    },
    longerThan30mins(eventParsed) {
      const start = new Date(`${eventParsed.start.date} ${eventParsed.start.time}`)
      const end = new Date(`${eventParsed.end.date} ${eventParsed.end.time}`)
      return end - start > 1800000
    },
    eventColour(event) {
      const { job, notSaved } = event
      if (notSaved) {
        return '#ccc'
      } else if (!job) {
        return '#fafafa'
      }
      return (job && this.jobColours[job.number]) || '#aaa'
    },
    setHolidayDays({ startDate, endDate }) {
      this.holidayDays = {}
      const holidays = this.$store.state.holidayDays
      if (startDate && endDate) {
        for (const [date, weekday] of Object.entries(holidays)) {
          if (date >= startDate && date <= endDate) {
            this.holidayDays[`${weekday}-bank-holiday`] = true
          }
        }
      }
    },
    entryHours(entry) {
      if (entry.type !== 'workshop' || entry.job) {
        const diff = new Date(entry.end) - new Date(entry.start)
        let workedHrs = diff / 1000 / 60 / 60
        if (entry.lunch_hours !== undefined) {
          if (entry.lunch_hours === 0) {
            return `${workedHrs}hrs (no lunch)`
          }
          const lunchMins = Math.floor(entry.lunch_hours * 60)
          workedHrs -= entry.lunch_hours
          return `${workedHrs}hrs + ${lunchMins}mins lunch`
        }
        return `${workedHrs}hrs`
      }
      return ''
    },
    async fetchData() {
      this.$store.commit('clearEntries')

      if (this.$store.state.user.admin) {
        await this.$store.dispatch('getUsers')
      }

      await this.$store.dispatch('getHolidayDays')
      // this.setHolidayDays()
      // console.log('loaded in', +new Date() - startTime)
    }
  },
  beforeMount() {
    this.fetchData()
    this.$store.dispatch('getJobColours')
  },
  mounted() {
    this.atDate = this.$route.query.date
    this.entryUID = this.$route.query.entry_uid

    this.scrollToWorkHours()

    this.$forceUpdate() // to show calendar title

    // allow to drag an event when creating
    window.addEventListener('touchmove', (e) => {
      if (this.currentEntry && !this.dialog) {
        e.preventDefault()
      } else {
        this.disableHold = true
      }
    }, { passive: false })
  }
}
</script>

<style lang="scss">
$calendar-event-right-empty: 0;

// shade out of work hours
div.v-calendar-daily__day-interval:nth-child(-n + 16),
div.v-calendar-daily__day-interval:nth-child(n + 34),
div.v-calendar-daily__day:nth-child(n + 7) div.v-calendar-daily__day-interval {
  background: #f7f7fa;
}

$holiday-colour: #cfcfdf;

.monday-bank-holiday div.v-calendar-daily__day:nth-child(2) div.v-calendar-daily__day-interval {
  background: $holiday-colour !important;
}

.tuesday-bank-holiday div.v-calendar-daily__day:nth-child(3) div.v-calendar-daily__day-interval {
  background: $holiday-colour !important;
}

.wednesday-bank-holiday div.v-calendar-daily__day:nth-child(4) div.v-calendar-daily__day-interval {
  background: $holiday-colour !important;
}

.thursday-bank-holiday div.v-calendar-daily__day:nth-child(5) div.v-calendar-daily__day-interval {
  background: $holiday-colour !important;
}

.friday-bank-holiday div.v-calendar-daily__day:nth-child(6) div.v-calendar-daily__day-interval {
  background: $holiday-colour !important;
}

.v-calendar-daily__pane {
  //touch-action: none;
}

.v-calendar .v-event-timed-container {
  margin-right: 0 !important;
}

.calendar {
  .v-input {
    flex-grow: 0;
    width: 150px;
  }
  .v-calendar-daily_head-day button {
    height: 30px;
    width: 30px;
  }
  div.event {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    user-select: none;

    &.lunch {
      color: #444;
      border: 2px solid #aaa;
    }

    &.leave {
      color: #444;
      border: 2px solid #7badff;
    }

    h4 {
      text-align: center;
      white-space: normal;
      padding: 0.5rem;
    }

    span.hours {
      position: absolute;
      top: 3px;
      left: 7px;
      color: #fff;
    }

    @media (max-width: 600px) {
      h4 {
        font-size: 0.5rem;
        padding: 0.1rem;
      }
    }
  }

  .day-hours {
    position: absolute;
    top: 0px;
    left: 5px;
    color: #555;
    font-size: 12px;
  }
}

.v-event-extend-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }
  &:hover::after, &.extending::after {
    display: block;
  }
}
</style>
